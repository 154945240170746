import { configureStore } from '@reduxjs/toolkit';
import { Configuration } from '../Configuration';
import { alertApi } from '../services/alert.service';
import { tagApi } from '../services/tag.service';
import { notificationApi } from '../services/notifications.service';

export const store = configureStore({
    devTools: !Configuration.isProduction,
    reducer: {
        [alertApi.reducerPath]: alertApi.reducer,
        [tagApi.reducerPath]: tagApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(alertApi.middleware, tagApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: rootReducer
export type AppDispatch = typeof store.dispatch;
