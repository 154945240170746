import React from 'react';

export type PaginationProps = { onPageChanged: (p: number) => void; page: number; pageSize: number; total: number };

export class Pagination extends React.Component<PaginationProps> {
    constructor(props: PaginationProps) {
        super(props);
    }

    range(start: number, stop: number, step: number) {
        return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
    }

    render() {
        let { page, pageSize, total } = this.props;
        const totalPages = Math.ceil(total / pageSize);

        const start = Math.max(1, page - 3);
        const end = Math.min(totalPages, page + 3);

        return (
            <div>
                {total > 0 && (
                    <nav style={{ display: 'inline-block', marginRight: '1em' }}>
                        <ul className="pagination pagination-sm">
                            <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => this.props.onPageChanged(page - 1)}>
                                    Previous
                                </a>
                            </li>
                            {this.range(start, end, 1).map((i) => (
                                <li key={i} className={`page-item ${page === i ? 'active' : ''}`}>
                                    <a className="page-link" onClick={() => this.props.onPageChanged(i)}>
                                        {i}
                                    </a>
                                </li>
                            ))}
                            <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                                <a className="page-link" onClick={() => this.props.onPageChanged(page + 1)}>
                                    Next
                                </a>
                            </li>
                        </ul>
                    </nav>
                )}
                <small>{total} results.</small>
            </div>
        );
    }
}
