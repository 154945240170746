import React, { useState } from 'react';
import { AlertsContainer, Panel, TagsTable } from '../../components';
import { useAppDispatch } from '../../store';
import { tagApi } from '../../services/tag.service';
import { alertApi } from '../../services/alert.service';
import './Home.scss';

const TABS_ENABLED = new Map([
    [0, 'active'],
    [1, 'muted'],
]);

export const Home = () => {
    const dispatch = useAppDispatch();
    const [tagId, setTagId] = useState(0);
    const [alertTitle, setAlertTitle] = useState('');
    const [skip, setSkip] = useState(true);
    const [selectedAlertId, setSelectedAlertId] = useState<number | null>(null);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const changeTab = (selectedTabIndex: number) => {
        dispatch(tagApi.util.invalidateTags([{ type: 'TagsSummary', id: 'LIST' }]));
        dispatch(alertApi.util.invalidateTags([{ type: 'AlertsSummary', id: 'LIST' }]));
        setSkip(true);
        setAlertTitle('');
        setSelectedAlertId(null);
        setSelectedTab(selectedTabIndex);
    };

    const seeTagDetails = (row: any) => {
        setSelectedAlertId(null);
        setTagId(row.values.id);
        setAlertTitle(row.values.description);
        setSkip(false);
    };

    const selectAlert = (alertId: number | null) => {
        setSelectedAlertId(alertId);
    };

    return (
        <div className="row">
            <div className="col-12">
                <Panel
                    id={'alerts-main-panel'}
                    key={'alerts-main-panel-key'}
                    title={'Channel Alerts'}
                    children={[
                        <TagsTable
                            key={'tab-container'}
                            selectedTab={selectedTab}
                            seeDetails={seeTagDetails}
                            changeTab={changeTab}
                            muted={TABS_ENABLED.get(selectedTab) !== 'active'}
                        />,
                        <AlertsContainer
                            key={'alert-container'}
                            tagId={tagId}
                            alertTitle={alertTitle}
                            skip={skip}
                            selectedAlertId={selectedAlertId}
                            selectAlert={selectAlert}
                            muted={TABS_ENABLED.get(selectedTab) !== 'active'}
                            setSelectedAlertId={setSelectedAlertId}
                        />,
                    ]}
                />
            </div>
        </div>
    );
};
