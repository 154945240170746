import React from 'react';
import { Icon } from '@vacasa/react-components-lib';
import './AlertTagList.scss';

type AlertTagListProps = {
    tags?: string[];
    editable: boolean;
    remove?: (tag: string) => void;
};

export const AlertTagList: React.FC<AlertTagListProps> = (props) => {
    const { tags, editable, remove } = props;

    return (
        <div>
            <ol className={'my-2 alert-tag-list'}>
                {tags &&
                    tags.map((tag) => (
                        <li className={'alert-tag-item'}>
                            <span className={'mx-2'}>{tag}</span>
                            {editable && remove && <Icon.X className={'alert-tag-remove'} onClick={() => remove(tag)} width={24} height={24} />}
                        </li>
                    ))}
            </ol>
        </div>
    );
};
