import React from 'react';
import { Button, Icon } from '@vacasa/react-components-lib';
import './SideEditPanel.scss';

type SideEditPanelProps = {
    title: string;
    customClass?: string;
    togglePanel: (value: boolean) => void;
    applyChanges: () => void;
};

export const SideEditPanel: React.FC<SideEditPanelProps> = (props) => {
    const { title, children, togglePanel, customClass, applyChanges } = props;

    return (
        <div className={'edit-panel-container' + (customClass ? ' ' + customClass : '')}>
            <header className={'edit-panel-header'}>
                <Icon.XCircleInverse style={{ cursor: 'pointer' }} height={18} width={18} onClick={() => togglePanel(false)} />
                <p>{title}</p>
                <Button customClass={'apply-button'} variant="primary" onClick={() => applyChanges()}>
                    Apply
                </Button>
            </header>
            <section className={'edit-panel-content'}>{children}</section>
        </div>
    );
};
