import React from 'react';
import { useParams } from 'react-router-dom';
import { Universal } from 'universal-types';
import { TextareaAutosize } from '@material-ui/core';
import { Alert } from '@common/typing';
import { ThemeProvider, Icon, Input, AutoCompleteSelect } from '@vacasa/react-components-lib';
import { SideEditPanel, AlertTagList } from '../../components';
import { tagApi, useGetTagsQuery } from '../../services/tag.service';
import { useUpdateAlertMutation, useTagAlertMutation } from '../../services/alert.service';
import { useAppDispatch } from '../../store';
import theme from '@vacasa/react-components-lib/lib/themes/default';
import './Alert.scss';

type EditAlertPanelProps = {
    customClass?: string;
    alert: Alert.SummaryDTO;
    toggleEditPanel: (value: boolean) => void;
    setSelectedAlertId: (alertId: number | null) => void;
};

export const EditAlertPanel: React.FC<EditAlertPanelProps> = (props) => {
    const dispatch = useAppDispatch();
    const { customClass, alert, toggleEditPanel, setSelectedAlertId } = props;
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();
    const [description, setDescription] = React.useState<string | undefined>(alert.description);
    const [solution, setSolution] = React.useState<string | undefined>(alert.solution);
    const [tags, setTags] = React.useState<string[]>(alert.tags_list ? alert.tags_list : []);
    const [selectedTag, setSelectedTag] = React.useState<string | null>('');
    const { data: tagList } = useGetTagsQuery({ channel });
    const [updateAlertMutation] = useUpdateAlertMutation();
    const [tagAlertMutation] = useTagAlertMutation();

    const updateAlertHandler = async () => {
        if (alert.description !== description || alert.solution !== solution) {
            await updateAlertMutation({
                id: alert.id,
                description,
                solution,
                channel,
            });
        }

        if (alert.tags_list !== tags && tags.length > 0) {
            await tagAlertMutation({
                alert_id: alert.id,
                tags_names: tags,
                channel,
            });

            setSelectedAlertId(null);
            dispatch(tagApi.util.invalidateTags([{ type: 'TagsSummary', id: 'LIST' }]));
        }

        toggleEditPanel(false);
    };

    const tagAlert = () => {
        if (selectedTag) {
            const tagsList = new Set([...tags, selectedTag]);
            setTags([...tagsList]);
        }
    };

    const untagAlert = (tag: string) => {
        setTags(tags.filter((t) => t !== tag));
    };

    return (
        <SideEditPanel customClass={customClass} title={'Edit alert'} togglePanel={toggleEditPanel} applyChanges={updateAlertHandler}>
            <Input
                customClass={'mb-2 edit-panel-input'}
                type={'text'}
                value={description ? description : ''}
                onChange={(ev) => setDescription(ev.target.value)}
                label={'Description:'}
            />

            <div className={'mb-2 edit-panel-textarea'}>
                <p>Solution:</p>
                <TextareaAutosize maxRows={4} minRows={1} value={solution} onChange={(ev) => setSolution(ev.target.value)} />
            </div>

            <div className={'mb-2 edit-panel-select'}>
                <p>Tags:</p>
                <div className={'row align-items-center m-0'}>
                    <ThemeProvider theme={theme}>
                        <AutoCompleteSelect
                            customClass={'col-10 p-0'}
                            options={tagList ? tagList.map((tag) => tag.name) : []}
                            value={selectedTag ? selectedTag : ''}
                            onChange={(tag) => {
                                setSelectedTag(tag);
                            }}
                            getOptionLabel={(option: string) => option}
                        />
                        <Icon.Plus className={'col-2 p-0 tag-alert-button'} onClick={tagAlert} width={28} height={28} />
                    </ThemeProvider>
                </div>
            </div>

            <AlertTagList tags={tags} editable={true} remove={untagAlert} />
        </SideEditPanel>
    );
};
