import React from 'react';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { Universal } from 'universal-types';
import { Alert as AlertTypes } from '@common/typing';
import { AlertMessage, Icon } from '@vacasa/react-components-lib';
import { Alert, Loading, Panel, PanelNotSelectedData, SuggestionPanel, EditAlertPanel } from '../../components';
import { useGetAlertsSummaryQuery, useLazyGetAlertsCsvQuery } from '../../services/alert.service';
import { AlertsContainerProps } from './Alert.type';
import './Alert.scss';

export const AlertsContainer: React.FC<AlertsContainerProps> = (props) => {
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();
    const { tagId, alertTitle, skip, selectedAlertId, selectAlert, muted, setSelectedAlertId } = props;
    const [isShowingEditPanel, setIsShowingEditPanel] = React.useState<boolean>(false);
    const [triggerCsvExport] = useLazyGetAlertsCsvQuery();
    const { data: alertsSummary, isFetching } = useGetAlertsSummaryQuery(
        {
            channel: channel,
            tagId,
            pageNumber: 1,
            pageSize: 10,
            muted,
        },
        {
            skip,
        }
    );

    const exportAlertsCsv = async () => {
        await triggerCsvExport({ tagId: tagId, channel: channel });
    };

    const toggleEditPanel = () => {
        setIsShowingEditPanel(!isShowingEditPanel);
    };

    const renderEditAlertPanel = () => {
        if (!_.isNil(selectedAlertId) && alertsSummary) {
            const [alertToEdit] = alertsSummary.items.filter((a: AlertTypes.SummaryDTO) => a.id === selectedAlertId);

            return (
                <EditAlertPanel
                    customClass={'edit-panel-sidebar-active'}
                    alert={alertToEdit}
                    toggleEditPanel={setIsShowingEditPanel}
                    setSelectedAlertId={setSelectedAlertId}
                />
            );
        }
    };

    const renderSuggestionPanel = (): JSX.Element => {
        if (!_.isNil(selectedAlertId) && alertsSummary) {
            const [alert] = alertsSummary.items.filter((a: AlertTypes.SummaryDTO) => a.id === selectedAlertId);
            return <SuggestionPanel key={'suggestion-panel'} title={'Suggested solution'} solution={alert.solution} />;
        }

        return (
            <div className="col-md-4 suggestion-panel-container">
                <AlertMessage text="Select an alert to view solutions" type="info" />
            </div>
        );
    };

    const renderLoading = () => (
        <div className={'col-md-8'} style={{ height: '200px' }}>
            <Loading />
        </div>
    );

    const renderAlertList = () => {
        if (isFetching) return renderLoading();

        if (alertsSummary) {
            return (
                <div className={'col-md-8 alert-list-container'}>
                    {alertsSummary.items.map((alertSummary: AlertTypes.SummaryDTO) => (
                        <Alert
                            toggleEditPanel={toggleEditPanel}
                            key={alertSummary.id}
                            selectAlert={selectAlert}
                            alertDetail={alertSummary}
                            selectedAlertId={selectedAlertId}
                        />
                    ))}
                </div>
            );
        }
    };

    return (
        <Panel
            customCss={'card-header-blank'}
            id={'alert-detail-panel'}
            key={'alert-detail-panel-key'}
            title={'Tag Alerts '}
            subTitle={alertTitle}
            icon={<Icon.Download height={'20'} width={'20'} onClick={() => exportAlertsCsv()} />}>
            <div className={'row'}>
                {!skip ? (
                    <>
                        {renderAlertList()}
                        {renderSuggestionPanel()}
                    </>
                ) : (
                    <>
                        <PanelNotSelectedData />
                    </>
                )}
                {isShowingEditPanel && renderEditAlertPanel()}
            </div>
        </Panel>
    );
};
