import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { StorageKeys, TagQuery } from '../types';
import { PaginatedResponseDTO, Paginated, Tag, ResponseDTO } from '@common/typing';

export const tagApi = createApi({
    reducerPath: 'tagApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_MONITOR_BASE_URL,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${localStorage.getItem(StorageKeys.ACCESS_TOKEN)}`);
            headers.set('x-identity-token', `${localStorage.getItem(StorageKeys.ID_TOKEN)}`);
            return headers;
        },
    }),
    tagTypes: ['TagsSummary', 'Tags'],
    endpoints: (builder) => ({
        getTagsSummary: builder.query<Paginated<Tag.SummaryDTO>, TagQuery>({
            query: (query: TagQuery) => {
                // transforming internal type to dto
                const params: Tag.QueryDTO = {
                    page: query.pageNumber,
                    page_size: query.pageSize,
                    muted: query.muted,
                };

                return { url: `/channels/${query.channel}/tags/summary`, params: params };
            },
            providesTags: () => [{ type: 'TagsSummary', id: 'LIST' }],
            transformResponse: (response: PaginatedResponseDTO<Tag.SummaryDTO>) => response.data,
        }),
        getTags: builder.query<Tag.TagDTO[], { channel: any }>({
            query: ({ channel }) => {
                return { url: `/channels/${channel}/tags` };
            },
            providesTags: () => [{ type: 'Tags', id: 'LIST' }],
            transformResponse: (response: ResponseDTO<Tag.TagDTO[]>) => response.data,
        }),
        addTag: builder.mutation<Tag.TagDTO, Tag.TagMutationDTO>({
            query: (tag: Tag.TagMutationDTO) => {
                return { url: `/channels/${tag.channel}/tags`, method: 'POST', body: tag };
            },
        }),
        updateTag: builder.mutation<Tag.TagDTO, Tag.TagMutationDTO>({
            query: (tag: Tag.TagMutationDTO) => {
                return { url: `/channels/${tag.channel}/tags/${tag.id}`, method: 'PUT', body: tag };
            },
            invalidatesTags: [{ type: 'TagsSummary', id: 'LIST' }],
        }),
    }),
});

export const { useGetTagsSummaryQuery, useAddTagMutation, useUpdateTagMutation, useGetTagsQuery } = tagApi;
