import React from 'react';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { Universal } from 'universal-types';
import { AlertMessage, Button, Icon, Modal, Tabs, Tooltip } from '@vacasa/react-components-lib';
import { EditTagPanel } from './EditTagPanel';
import { Configuration } from '../../Configuration';
import { AddTagModal, DynamicTable } from '../../components';
import { useGetTagsSummaryQuery } from '../../services/tag.service';
import { TableProps, tableRow } from './Tags.type';
import './TagsTable.scss';

export const TagsTable: React.FC<TableProps> = (props) => {
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();
    const { seeDetails, selectedTab, changeTab, muted } = props;
    const [isShowingEditPanel, setIsShowingEditPanel] = React.useState(false);
    const [tagToEdit, setTagToEdit] = React.useState(null);
    const [showAddTagModal, setShowAddTagModal] = React.useState(false);

    const pollingInterval = Configuration.getTagsPollingInterval();
    const { data: tagsSummary, error, isFetching } = useGetTagsSummaryQuery(
        {
            channel: channel,
            pageNumber: 1,
            pageSize: 10,
            muted,
        },
        {
            pollingInterval,
        }
    );

    const columns = React.useMemo(() => {
        return [
            {
                accessor: 'id',
                width: 0,
                Cell: (row: tableRow) => <div style={{ display: 'none' }}>{row.value}</div>,
            },
            {
                Header: 'Occurrence',
                accessor: 'times',
                width: 1000,
                Cell: (row: tableRow) => <div className={'row-data-text'}>{row.value}</div>,
            },
            {
                Header: 'Tag',
                accessor: 'name',
                width: 1500,
                Cell: (row: tableRow) => <div className={'row-data-text tag-text'}>{row.value}</div>,
            },
            {
                Header: 'Description',
                accessor: 'description',
                width: 6000,
                Cell: (row: tableRow) => <div className={'row-data-text tag-text'}>{row.value}</div>,
            },
            {
                Header: 'Last Notified',
                width: 2000,
                accessor: (row: { last_notified: Date }) => moment(row.last_notified).format('MMM-DD hh:mm'),
                Cell: (row: tableRow) => <div className={'row-data-text'}>{row.value}</div>,
            },
            {
                Header: 'Actions',
                accessor: 'actions',
                width: 1000,
                Cell: ({ row }: any) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                            }}>
                            <Tooltip message={'See Details'}>
                                <a
                                    tabIndex={0}
                                    role={'button'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        seeDetails(row);
                                    }}>
                                    <Icon.ZoomIn className={'action-button'} />
                                </a>
                            </Tooltip>
                            <Tooltip message={'Edit tag'}>
                                <a
                                    tabIndex={0}
                                    role={'button'}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        const { id, name, description } = row.values;
                                        toggleEditPanel(true, { id, name, description });
                                    }}>
                                    <Icon.Settings className={'action-button'} />
                                </a>
                            </Tooltip>
                        </div>
                    );
                },
            },
        ];
    }, []);

    const toggleEditPanel = (value: boolean, tag: any) => {
        setIsShowingEditPanel(value);
        if (value) {
            setTagToEdit(tag);
            return;
        }

        setTagToEdit(null);
    };

    const renderError = (): JSX.Element => {
        return (
            <div className="tab-body">
                <AlertMessage type={'error'} text={'Information cannot be obtained'} />
            </div>
        );
    };

    const renderTagList = (key: string) => {
        if (error) return renderError();

        return (
            <div key={key} className="tab-body">
                {isShowingEditPanel && <EditTagPanel tag={tagToEdit} customClass={'edit-panel-sidebar-active'} toggleEditPanel={toggleEditPanel} />}
                {tagsSummary && (
                    <div className={'card'}>
                        <div className={'alert-detail-table-over'}>
                            <DynamicTable columns={columns} data={tagsSummary.items} isFetching={isFetching} />
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className={'main-buttons-container'}>
                <Button customClass={'main-button'} variant="secondary" onClick={() => setShowAddTagModal(true)}>
                    Add tag
                </Button>
            </div>
            <Tabs
                key={'dynamic-tab-alerts'}
                selected={selectedTab}
                onChange={(index) => changeTab(index)}
                variant="default"
                tabs={[
                    {
                        id: 'tab-alerts-active',
                        label: 'Active',
                        component: renderTagList('active-tab'),
                    },
                    {
                        id: 'tab-alerts-muted',
                        label: 'Muted',
                        component: renderTagList('muted-tab'),
                    },
                ]}
            />

            {/* TODO: add z-index to Modal component of Vacasa library */}
            <div className={'modal-fix'}>
                <Modal
                    showModal={showAddTagModal}
                    setShowModal={setShowAddTagModal}
                    size="small"
                    children={<AddTagModal setShowAddTagModal={setShowAddTagModal} />}
                />
            </div>
        </>
    );
};
