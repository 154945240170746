import React, { useState } from 'react';
import { Loading, Panel } from '../../components';
import { useLazyGetNotificationsQuery } from '../../services/notifications.service';
import moment from 'moment';
import { AlertMessage, Modal } from '@vacasa/react-components-lib';
import { useLocation, useParams } from 'react-router-dom';
import { ENTITY_TYPE_LIST } from '../../types/notification.type';
import { Pagination } from '../../components/Pagination/Pagination';
import { Universal } from 'universal-types';

const PAGE_SIZE = 20;

export const Notifications: React.FC = () => {
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();

    const [showExtendedPropertiesModal, setShowExtendedPropertiesModal] = useState(false);
    const [extendedProperties, setExtendedProperties] = useState<Universal.Monitor.NotificationErrorProperties>();

    const search = new URLSearchParams(useLocation().search);
    const [entityId, setEntityId] = useState<string>(search.get('entity_id') || '');
    const [entityType, setEntityType] = useState<string>(search.get('entity_type') || 'channel-unit');
    const [page, setPage] = useState<number>(1);

    const [trigger, { data: pagedNotifications, isFetching, error }] = useLazyGetNotificationsQuery();

    function loadNotifications(pageNumber: number) {
        if (!entityId || !entityType) {
            alert('Entity Id and Entity Type are required.');
            return;
        }

        setPage(pageNumber);
        trigger({ channel, pageNumber, pageSize: PAGE_SIZE, entityId, entityType });
    }

    const renderStatus = (status: string) => {
        const stylesMap: { [status: string]: string } = { ok: 'bg-info', error: 'bg-danger', warning: 'bg-warning' };

        return <span className={'badge ' + (status in stylesMap ? stylesMap[status] : '')}>{status}</span>;
    };

    function showExtendedProperties(extendedProperties: Universal.Monitor.NotificationErrorProperties) {
        setShowExtendedPropertiesModal(true);
        setExtendedProperties(extendedProperties);
    }

    function renderSearch() {
        return (
            <div className="row mb-2">
                <div className="col-auto">
                    <input
                        className="form-control"
                        type="text"
                        value={entityId}
                        onChange={(e) => setEntityId(e.target.value)}
                        placeholder="Entity Id"
                    />
                </div>
                <div className="col-auto">
                    <select className="form-control" value={entityType} onChange={(e) => setEntityType(e.target.value)}>
                        <option disabled>-- Entity Type --</option>
                        {ENTITY_TYPE_LIST.map((et) => (
                            <option key={et.value} value={et.value}>
                                {et.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-auto">
                    <button type="submit" className="btn btn-primary" onClick={() => loadNotifications(1)}>
                        Search
                    </button>
                </div>
            </div>
        );
    }

    const renderNotificationList = () => {
        return (
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Entity</th>
                            <th>Topic</th>
                            <th>Action</th>
                            <th>Status</th>
                            <th>Status Details</th>
                            <th>Ext. Properties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isFetching &&
                            pagedNotifications &&
                            pagedNotifications.items.map((n, i) => (
                                <tr key={i}>
                                    <td>
                                        <small>{moment(n.created_at).format('YYYY-MM-DD, hh:mm:ss')}</small>
                                    </td>
                                    <td>
                                        <code>{n.entity_type}</code> | {n.entity_id}
                                    </td>
                                    <td>{n.topic}</td>
                                    <td>{n.action}</td>
                                    <td>{renderStatus(n.status)}</td>
                                    <td>{n.status_details} </td>
                                    <td>{n.extended_properties && <a onClick={() => showExtendedProperties(n.extended_properties)}>show</a>}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>

                {isFetching && <Loading />}

                <Pagination
                    page={page}
                    total={pagedNotifications?.count ?? 0}
                    pageSize={PAGE_SIZE}
                    onPageChanged={(p: number) => loadNotifications(p)}
                />
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <Panel id={'notifications-panel'} title="Notifications">
                {renderSearch()}
                {error ? <AlertMessage type="error" text={JSON.stringify(error)} /> : <div />}
                {renderNotificationList()}
            </Panel>
            <Modal showModal={showExtendedPropertiesModal} setShowModal={setShowExtendedPropertiesModal} size="large">
                <p>Response</p>
                <code>{extendedProperties?.response?.data}</code>
            </Modal>
        </div>
    );
};
