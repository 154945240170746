import React from 'react';
import './Loading.scss';

export const Loading: React.FC = () => {
    return (
        <div className="loading">
            &nbsp;
            <div className={'loader-dots loader-dots--sm loader-dots--midnight'}>
                <div className={'bounce1'}></div>
                <div className={'bounce2'}></div>
                <div className={'bounce3'}></div>
            </div>
        </div>
    );
};
