import React from 'react';
import { PanelProps } from './Panel.type';
import './Panel.scss';

export const Panel: React.FC<PanelProps> = (props) => {
    const { title, subTitle, image, children, icon, id, customCss } = props;

    return (
        <div id={id} className="card">
            <div className={`${customCss ? customCss : 'card-header'}`}>
                <div className="row">
                    <div className="col-11">
                        <span className={'title-panel'}>{title}</span>
                        {subTitle && <span className={'subtitle-panel'}>{subTitle}</span>}
                    </div>
                    {icon && (
                        <div className="col-1 icon-align">
                            <a tabIndex={0} role="button" className="button-download-units" onClick={() => {}} onKeyPress={() => {}}>
                                {icon}
                            </a>
                        </div>
                    )}
                </div>
            </div>
            <div className="card-body">
                {image && <p className="card-text">{image}</p>}
                {children}
            </div>
        </div>
    );
};
