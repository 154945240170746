export type NotificationQuery = {
    entityId: string;
    entityType: string;
    channel: string;
    pageNumber: number;
    pageSize: number;
};

// TODO: update and reuse universal
export enum EntityType {
    Uuid = 'UUID',
    LegacyUnit = 'LEGACY-UNIT',
    Cluster = 'CLUSTER',
    ChannelUnit = 'CHANNEL-UNIT',
    ChannelCluster = 'CHANNEL-CLUSTER',
    ChannelProperty = 'CHANNEL-PROPERTY', // missing in universal
    Channel = 'CHANNEL',
    ChannelAccount = 'CHANNEL-ACCOUNT',
    Fee = 'FEE',
    Tax = 'TAX',
    ChannelReservation = 'CHANNEL-RESERVATION',
}

export const ENTITY_TYPE_LIST: { value: string; label: string }[] = Object.values(EntityType).map((et) => ({
    value: et.toLowerCase(),
    label: et,
}));
