import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, ImplicitFlow } from '@vacasa/react-components-lib';
import { Container } from '@material-ui/core';
import theme from '@vacasa/react-components-lib/lib/themes/default';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Routes } from './routes';
import { store } from './store';
import { Configuration } from './Configuration';

export const history = createBrowserHistory();

function App() {
    return (
        // @ts-ignore
        <ImplicitFlow {...Configuration.getIDPConfiguration()}>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <Container maxWidth={false}>
                        <Router history={history}>
                            <Routes />
                        </Router>
                    </Container>
                </Provider>
            </ThemeProvider>
        </ImplicitFlow>
    );
}

export default App;
