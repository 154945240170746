import React from 'react';
import { Alert as AlertTypes } from '@common/typing';
import { AlertProps } from './Alert.type';
import { AlertTagList, Loading } from '../../components';
import { Icon, Tooltip } from '@vacasa/react-components-lib';
import { useResolveAlertMutation, useUpdateAlertMutation } from '../../services/alert.service';
import { useAppDispatch } from '../../store';
import { tagApi } from '../../services/tag.service';
import { Configuration } from '../../Configuration';
import './Alert.scss';
import { useParams } from 'react-router-dom';
import { Universal } from 'universal-types';

export const Alert: React.FC<AlertProps> = (props) => {
    const dispatch = useAppDispatch();
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();
    const { selectAlert, alertDetail, selectedAlertId, toggleEditPanel } = props;
    const [updateAlert, { isLoading: isUpdating }] = useUpdateAlertMutation();
    const [resolveAlert, { isLoading: isResolving }] = useResolveAlertMutation();
    const entitiesIds = alertDetail.entity_ids || [];

    const handleMuteAlert = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        selectAlert(null);
        await updateAlert({
            ...alertDetail,
            channel,
            mute: !alertDetail.mute,
        });
        dispatch(tagApi.util.invalidateTags([{ type: 'TagsSummary', id: 'LIST' }]));
    };

    const handleResolveAlertManually = async (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        await resolveAlert({
            alertId: alertDetail.id,
            channel,
        });
        dispatch(tagApi.util.invalidateTags([{ type: 'TagsSummary', id: 'LIST' }]));
    };

    //TODO: It can be redirected to router or admin depending on the case.
    const renderEntityLink = (entityId: string): JSX.Element => {
        return (
            <>
                <a
                    target={'_blank'}
                    rel={'noreferrer'}
                    href={`${Configuration.getAdminUrl()}/admin/dashboard/units/edit?UnitID=${entityId.replace(',', '')}`}>
                    <span className={'unit-alert-details'}>{entityId}</span>
                </a>
            </>
        );
    };

    const renderFooter = (entitiesIds: string[]): JSX.Element => {
        const filters = entitiesIds.join(',');

        return (
            <>
                <div className="row my-1">
                    <div className="col-8">
                        <span className={'footer-message-alerts-details'}>{`(Showing ${entitiesIds.slice(0, 20).length} of ${
                            entitiesIds.length
                        })`}</span>
                    </div>
                    <div className="col-4">
                        <Tooltip className="float-end" message={'Go to view all IDs'}>
                            <a
                                role={'button'}
                                target={'_blank'}
                                rel="noreferrer"
                                href={`${Configuration.getRouterUrl()}/airbnb/units?filter=${filters}`}>
                                <span className={'footer-button-alerts-details'}>
                                    Go to view all IDs
                                    <Icon.ExternalLink height={20} width={20} />
                                </span>
                            </a>
                        </Tooltip>
                    </div>
                </div>
            </>
        );
    };

    const renderHeader = (): JSX.Element => {
        return (
            <div className={'alert-header-container'}>
                <div className={'row mb-2'}>
                    <span className={'col-8 title-alert-details'}>Alert</span>
                    <div className={'col-4 alert-actions-container'}>
                        {alertDetail.configuration.resolve_as && alertDetail.configuration.resolve_as.type == AlertTypes.ResolveAsType.MANUAL && (
                            <div className={'mx-1'} onClick={(event) => handleResolveAlertManually(event)}>
                                <Tooltip message={'Resolve alert manually'}>
                                    <a role={'button'} target={'_blank'} rel="noreferrer">
                                        <Icon.RefreshCCW height={20} width={20} />
                                    </a>
                                </Tooltip>
                            </div>
                        )}
                        <div className={'mx-1'} onClick={(event) => handleMuteAlert(event)}>
                            {!alertDetail.mute ? (
                                <Tooltip message={'Mute alert'}>
                                    <a role={'button'} target={'_blank'} rel="noreferrer">
                                        <Icon.VolumeX height={20} width={20} />
                                    </a>
                                </Tooltip>
                            ) : (
                                <Tooltip message={'Unmute alert'}>
                                    <a role={'button'} target={'_blank'} rel="noreferrer">
                                        <Icon.Volume2 height={20} width={20} />
                                    </a>
                                </Tooltip>
                            )}
                        </div>
                        <div className={'mx-1'} onClick={() => toggleEditPanel()}>
                            <Tooltip message={'Edit alert'}>
                                <a role={'button'} target={'_blank'} rel="noreferrer">
                                    <Icon.Settings height={20} width={20} />
                                </a>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <span className={'subtitle-alert-details'}>{alertDetail.description}</span>

                <AlertTagList tags={alertDetail.tags_list} editable={false} />

                <span className={'small-subtitle-alert-details'}>{alertDetail.entity_types.join(', ')} Affected</span>
                <hr className={'my-1 footer-line-alerts-details'} />
            </div>
        );
    };

    if (isUpdating) {
        return (
            <div style={{ height: '200px' }}>
                <Loading />
            </div>
        );
    }

    return (
        <div className="col-12">
            <div
                key={`card-error`}
                className="card alert-container"
                style={alertDetail.id === selectedAlertId ? { border: '4px solid #ccd6db', width: '100%' } : { width: '100%' }}
                onClick={() => selectAlert(alertDetail.id)}>
                <div className="card-body">
                    {renderHeader()}
                    {entitiesIds &&
                        entitiesIds.slice(0, 20).map((entityId: string) => {
                            if (entitiesIds[entitiesIds.length - 1] === entityId) {
                                return renderEntityLink(entityId);
                            }
                            return renderEntityLink(`${entityId} , `);
                        })}
                    {entitiesIds && renderFooter(entitiesIds)}
                </div>
            </div>
        </div>
    );
};
