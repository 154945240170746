import {Alert} from '@common/typing';

export const createCsvReport = (items: Alert.AlertCsvDTO[]): string => {
    let csvFile = `Channel, Entity type, Entity ID, Alert detail, Last update \n`;

    items.forEach(item => {
        const values: Array<any> = [];
        values.push([
            `${item.channel}`,
            `${item.entity_type}`,
            `${item.entity_id}`,
            `"${item.detail}"`,
            `${item.updated_at}`,
        ]);
        csvFile += `${values.join(",")}\n`;
    });

    return csvFile;
};

export const exportCsvFile = (csvFile: string, filename: string) => {
    const csvFileBlob = new Blob([csvFile], {type: "text/csv"});
    let a = document.createElement("a");
    a.download = filename;
    a.href = window.URL.createObjectURL(csvFileBlob);
    a.click();
}