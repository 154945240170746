import React, { useEffect } from 'react';
import './SuggestionPanel.scss';
import { SuggestionPanelProps } from './SugestionPanel.type';

export const SuggestionPanel: React.FC<SuggestionPanelProps> = (props) => {
    const { title, children, solution } = props;

    useEffect(() => {}, [children]);

    const renderSolution = (solution: string | undefined) => {
        if (!solution) {
            return (
                <div className="card-text info-suggestion-panel">
                    <>Without a defined solution yet, contact the Product Manager.</>
                </div>
            );
        }

        return (
            <div className="card-text info-suggestion-panel">
                <>{solution}</>
            </div>
        );
    };

    return (
        <div className="col-md-4 suggestion-panel-container">
            <div className={'m-3'} />
            <span className={'title-suggestion-panel'}>{title}</span>
            <div className={'m-3'} />
            {renderSolution(solution)}
            <div className={'m-5'} />
            <div className={'suggestion-panel-children'}>{children}</div>
        </div>
    );
};
