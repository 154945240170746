import React from "react";
import {Redirect, Route, RouteProps, Switch} from "react-router-dom";
import {Home, Notifications} from "./pages";

export enum AppRoutes {
    HOME = "/:channel/",
    NOTIFICATIONS = "/:channel/notifications",
}

const routes: RouteProps[] = [
    {path: AppRoutes.HOME, exact: true, component: Home},
    {path: AppRoutes.NOTIFICATIONS, exact: true, component: Notifications},
];

export const Routes = () => {
    return (
        <React.Fragment>
            <Switch>
                {routes.map((route, i) => {
                    return <Route key={i} {...route} />;
                })}
                <Redirect from="*" exact={true} to="/airbnb" />
            </Switch>
        </React.Fragment>
    )
}
