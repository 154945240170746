import React from 'react';
import { Button, Icon, Input } from '@vacasa/react-components-lib';
import { Loading } from '../../components';
import { useAddTagMutation } from '../../services/tag.service';
import { useParams } from 'react-router-dom';
import { Universal } from 'universal-types';

export const AddTagModal = (props: any) => {
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();
    const { setShowAddTagModal } = props;
    const [addTag, { isLoading: isUpdating, isError }] = useAddTagMutation();
    const [name, setName] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('');

    const addTagHandler = async (name: string, description: string): Promise<void> => {
        await addTag({
            name,
            description,
            channel,
        });

        if (isError) {
            alert('error adding tag');
        }

        setShowAddTagModal(false);
    };

    return (
        <section style={{ display: 'flex', flexDirection: 'column' }}>
            <h3>Add Tag</h3>
            <Input type={'text'} placeholder={'Tag name'} value={name} onChange={(ev) => setName(ev.target.value)} label={'Name:'} />
            <Input
                type={'text'}
                placeholder={'Tag description'}
                value={description}
                onChange={(ev) => setDescription(ev.target.value)}
                label={'Description:'}
            />
            <br />
            {!isUpdating ? (
                <Button customClass={'main-button'} variant="primary" onClick={() => addTagHandler(name, description)}>
                    <Icon.Plus width={24} height={24} style={{ marginRight: '0.25em' }} />
                    Add
                </Button>
            ) : (
                <Loading />
            )}
        </section>
    );
};
