import React from 'react';
import { DynamicTableProps } from './DynamicTable.type';
import './DynamicTable.scss';
import { useBlockLayout, useFilters, useTable } from 'react-table';
import { FixedSizeList } from 'react-window';
import { Loading } from '../Loading/Loading';

export const DynamicTable: React.FC<DynamicTableProps> = (props) => {
    const { columns, data, isFetching } = props;
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data,
        },
        useFilters,
        useBlockLayout
    );

    const renderLoading = (): JSX.Element => {
        return (
            <div className="tab-body">
                <Loading />
            </div>
        );
    };

    const RenderRow = React.useCallback(
        ({ index, style }) => {
            const row = rows[index];
            prepareRow(row);
            return (
                <div
                    {...row.getRowProps({
                        style,
                    })}
                    className="tr row-data-table">
                    {row.cells.map((cell) => {
                        return (
                            <div {...cell.getCellProps()} className="td">
                                {cell.render('Cell')}
                            </div>
                        );
                    })}
                </div>
            );
        },
        [prepareRow, rows]
    );

    const renderRows = () => {
        if (isFetching) return renderLoading();

        return (
            <div {...getTableBodyProps()}>
                <FixedSizeList height={350} itemCount={rows.length} itemSize={42} width="100%">
                    {RenderRow}
                </FixedSizeList>
            </div>
        );
    };

    return (
        <div {...getTableProps()} className="table">
            <div>
                {headerGroups.map((headerGroup) => (
                    <div {...headerGroup.getHeaderGroupProps()} className="tr row-header-table">
                        {headerGroup.headers.map((column) => (
                            <div {...column.getHeaderProps()} className="th">
                                {column.render('Header')}
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {renderRows()}
        </div>
    );
};
