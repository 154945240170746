import React from 'react';
import { ImageMaintenance } from '../../../assets/images';
import './PanelNotSelectedData.scss';

export const PanelNotSelectedData: React.FC = () => {
    return (
        <>
            <div className={'m-4'} />
            <ImageMaintenance />
            <div className={'m-3'} />
            <span className={'title-panel-not-selected-data'}>There is no tag here selected to show details</span>
            <span className={'subtitle-panel-not-selected-data'}>Select a tag to start playing</span>
            <div className={'m-3'} />
        </>
    );
};
