export class Configuration {
    public static isProduction = process.env.REACT_APP_CUSTOM_NODE_ENV === 'production';

    static getAdminUrl = () => {
        const { REACT_APP_ADMIN_URL } = process.env;
        return REACT_APP_ADMIN_URL;
    };

    static getRouterUrl = () => {
        const { REACT_APP_ROUTER_URL } = process.env;
        return REACT_APP_ROUTER_URL;
    };

    static getIDPConfiguration = () => {
        const { REACT_APP_IDP_CLIENT_ID, REACT_APP_MONITOR_DOMAIN, REACT_APP_IDP_REDIRECT_URI, REACT_APP_CUSTOM_NODE_ENV } = process.env;

        const envs = {
            production: 'prod',
            staging: 'stage',
            development: 'dev',
            local: 'stage',
        };

        return {
            clientId: REACT_APP_IDP_CLIENT_ID,
            scopes: 'units:read',
            audience: REACT_APP_MONITOR_DOMAIN,
            redirectUrl: REACT_APP_IDP_REDIRECT_URI,
            directoryHint: 'onelogin',
            // @ts-ignore
            env: envs[REACT_APP_CUSTOM_NODE_ENV],
        };
    };

    static getTagsPollingInterval = (): number => {
        const { REACT_APP_POOLING_INTERVAL } = process.env;
        // @ts-ignore
        return parseInt(REACT_APP_POOLING_INTERVAL);
    };
}
