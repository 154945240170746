import React from 'react';
import { useParams } from 'react-router-dom';
import { Universal } from 'universal-types';
import { Input } from '@vacasa/react-components-lib';
import { SideEditPanel } from '../../components';
import { useUpdateTagMutation } from '../../services/tag.service';
import { useAppDispatch } from '../../store';
import { alertApi } from '../../services/alert.service';

export const EditTagPanel: React.FC<any> = (props) => {
    const dispatch = useAppDispatch();
    const { channel } = useParams<{ channel: Universal.Router.Channel }>();
    const { customClass, toggleEditPanel, tag } = props;
    const [updateTag, { isError }] = useUpdateTagMutation();
    const [name, setName] = React.useState(tag.name);
    const [description, setDescription] = React.useState(tag.description);

    const updateTagHandler = async () => {
        await updateTag({
            id: tag.id,
            name,
            description,
            channel,
        });

        if (isError) {
            alert('Error updating tag');
        }

        dispatch(alertApi.util.invalidateTags([{ type: 'AlertsSummary', id: 'LIST' }]));
        toggleEditPanel(false);
    };

    return (
        <SideEditPanel title={'Edit tag'} customClass={customClass} togglePanel={toggleEditPanel} applyChanges={updateTagHandler}>
            <Input customClass={'mb-2 edit-panel-input'} type={'text'} value={name} onChange={(ev) => setName(ev.target.value)} label={'Name:'} />
            <Input
                customClass={'mb-2 edit-panel-input'}
                type={'text'}
                value={description}
                onChange={(ev) => setDescription(ev.target.value)}
                label={'Description:'}
            />
        </SideEditPanel>
    );
};
